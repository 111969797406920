<template>
<div class="main-content">
  <HeroSection />
  <AboutSection />
  <ServicesSection />
  <GallerySection />
  <TestimonialsSection />
  <LocationSection />
  <ContactSection />
</div>
</template>

<script>
import { HeroSection, AboutSection, ServicesSection, GallerySection, TestimonialsSection, LocationSection, ContactSection } from "../sections/index.js";

export default {
  name: "HomePage",
  components: {
    HeroSection,
    AboutSection,
    ServicesSection,
    GallerySection,
    TestimonialsSection,
    LocationSection,
    ContactSection,
  }
};
</script>

<style lang="scss"></style>