<template>
  <section class="content__section section__services py-5" id="Services">
    <div class="container">
      <h2 class="section__title text-uppercase text-center mb-5 hidden">
        {{ $t("services.title") }}
      </h2>
      <div class="row justify-content-center">
        <ServiceCard v-for="(name, index) in serviceGroups" :serviceGroupName="name" :key="index" />
      </div>
    </div>
  </section>
</template>
<script>
import ServiceCard from "../misc/ServiceCard.vue";

export default {
  name: "ServicesSection",
  components: { ServiceCard },
  data() {
    return {
      serviceGroups: Object.keys(this.$t("services.serviceGroup")),
    };
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  },
};
</script>
<style lang="scss">
.section__services {
  .card__container {
    border: none;
    height: 350px;
  }
}
</style>
