<template>
  <section class="content__section section__hero">
    <h1 class="hero__title m-0">
      {{ $t("hero.title.primary") }} <br />
      {{ $t("hero.title.secondary") }}
    </h1>
    <video autoplay loop muted playsinline class="hero__video">
      <source src="../../assets/videos/header_bg_video.mp4" type="video/mp4" />
    </video>
  </section>
</template>
<script>
export default {
  name: "HeroSection",
};
</script>
<style lang="scss">
.section__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 75px);
  overflow: hidden;
  margin-top: 75px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  .hero__title {
    color: $white;
    font-size: 80px;
    font-weight: 600;
    font-family: "Poiret One", cursive;
  }
  .hero__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
}
@media screen and (max-width: 1399px) {
  .section__hero {
    .hero__title {
      font-size: 60px;
    }
  }
}
@media screen and (max-width: 767px) {
  .section__hero {
    .hero__title {
      font-size: 50px;
      text-align: center;
      width: 80%;
    }
  }
}
@media screen and (max-width: 425px) {
  .section__hero {
    .hero__title {
      font-size: 45px;
    }
  }
}
</style>
