<template>
  <section class="content__section section__testimonials py-5 hidden" id="Testimonials">
    <h2 class="section__title text-uppercase text-center mb-5">
      {{ $t("testimonials.title") }}
    </h2>
    <div class="container">
      <TestimonialsSwiper />
    </div>
  </section>
</template>
<script>
import TestimonialsSwiper from "../sliders/TestimonialsSwiper.vue";

export default {
  name: "TestimonialsSection",
  components: { TestimonialsSwiper },
};
</script>
<style lang="scss"></style>
