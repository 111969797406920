<template>
  <div class="col-lg-3 col-sm-6 col-12 mb-4 hidden">
    <div class="service__card overflow-hidden">
      <div class="service__image d-flex align-items-center justify-content-center">
        <div class="image__box p-3">
          <img :src="loadSrc(this.serviceGroupName, 'svg', 'services')" alt="Service Icon" />
        </div>
      </div>
      <div class="service__info text-center p-3">
        <h3 class="service__title text-uppercase">
          {{ $t(`services.serviceGroup.${serviceGroupName}.title`) }}
        </h3>
        <p class="service__description mt-4">
          {{ $t(`services.serviceGroup.${serviceGroupName}.description`) }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { loadSrc } from "../../utils/utils.js";

export default {
  name: "ServiceCard",
  props: ["serviceGroupName"],
  methods: { loadSrc },
};
</script>
<style lang="scss">
.service__card {
  font-family: "Cormorant Garamond", serif;
  .service__image {
    position: relative;
    .image__box {
      width: 100%;
      img {
        width: 100%;
        aspect-ratio: 1 / 1;
        filter: brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(0%) hue-rotate(39deg) brightness(101%) contrast(91%);
      }
    }
  }
  .service__info {
    height: 100%;
    .service__title {
      font-weight: 700;
      font-size: 26px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: $dark-grey;
        left: 50%;
        transform: translateX(-50%);
        bottom: -8px;
      }
    }
    .service__description {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 1399px) {
  .service__card {
    .service__info {
      .service__title {
        font-size: 24px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .service__card {
    .service__image {
      .image__box {
        width: 65%;
      }
    }
    .service__info {
      .service__title {
        font-size: 22px;
      }
    }
  }
}
</style>
