<template>
  <section class="content__section section__about py-5 hidden" id="About">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-12">
          <div class="about__info text-lg-start text-center">
            <h2 class="section__title text-uppercase d-lg-inline-block mb-5">
              {{ $t("about.title") }}
            </h2>
            <p class="about__description">
              {{ $t("about.description") }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-12 order-lg-last order-first">
          <div class="about__image text-center m-auto mb-4 mb-lg-0">
            <img
              :src="loadSrc('about_bg.svg', null, 'backgrounds')"
              alt="About us"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { loadSrc } from "@/utils/utils";
export default {
  name: "AboutSection",
  methods: {
    loadSrc,
  },
};
</script>
<style lang="scss">
.section__about {
  .about__description {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    font-weight: 500;
  }
  .about__image {
    aspect-ratio: 1 / 1;
    img {
      height: 100%;
    }
  }
}
@media screen and (max-width: 1399px) {
  .section__about {
    .about__info {
      .about__description {
        font-size: 22px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .section__about {
    .about__image {
      max-height: 350px;
    }
  }
}
@media screen and (max-width: 576px) {
  .section__about {
    .about__info {
      .about__description {
        font-size: 20px;
      }
    }
  }
}
</style>
