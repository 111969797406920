<template>
      <button class="rounded-circle scroll_top-btn" ref="scrollToTopBtn" aria-label="Scroll to top" @click="scrollToTop">
            <i class="fa-solid fa-arrow-up"></i>
      </button>
</template>
<script>
export default {
      name: "ScrollToTopBtn",
      methods: {
            scrollToTop() {
                  window.scrollTo(0, 0);
            },
      },
      mounted() {
            window.addEventListener("scroll", () => {
                  this.$refs.scrollToTopBtn.classList[`${window.scrollY > 500 ? "add" : "remove"}`]("show");
            });
      },
};
</script>
<style lang="scss">
.scroll_top-btn {
      position: fixed;
      right: 50px;
      bottom: 55px;
      width: 50px;
      aspect-ratio: 1 / 1;
      background: linear-gradient(-45deg, #be9e44, #d8c690, #be9e44);
      border: 2px solid $dark-grey;
      opacity: 0;
      transform: translateY(15px);
      transition: all 0.3s ease;
      &.show {
            opacity: 1;
            transform: translateY(0);
            cursor: pointer;
      }
}
@media screen and (max-width: 767px) {
      .scroll_top-btn {
            width: 40px;
            bottom: 55px;
            right: 25px;
      }
}
</style>
