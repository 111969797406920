<template>
  <div class="swiper__container py-5">
    <div class="swiper-nav d-flex justify-content-end">
      <div class="swiper-button-prev testimonials-btn me-2">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <div class="swiper-button-next testimonials-btn">
        <i class="fa-solid fa-arrow-right"></i>
      </div>
    </div>
    <div class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide px-2" v-for="(testimonial, index) in testimonials" :key="index">
          <div class="row align-items-center">
            <div class="col-lg-5 col-12">
              <div class="testimonial__image rounded-circle m-auto">
                <img :src="loadSrc(testimonial.image_url, null, 'testimonials')" alt="Customer testimonial" />
              </div>
            </div>
            <div class="col-lg-7 col-12">
              <div class="testimonial__info text-lg-start text-center mt-4 mt-lg-0">
                <h2 class="testimonial__name fw-bold mb-1">
                  {{ $t(`testimonials.reviews[${index}].name`) }}
                </h2>
                <div class="testimonial__rating mb-3">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <p class="testimonial__message mb-3">
                  {{ $t(`testimonials.reviews[${index}].message`) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { loadSrc } from "../../utils/utils.js";

export default {
  name: "TestimonialsSwiper",
  methods: { loadSrc },
  data() {
    return {
      testimonials: this.$t("testimonials.reviews"),
    };
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Autoplay, Pagination],
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      speed: 400,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 3700,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next.testimonials-btn",
        prevEl: ".swiper-button-prev.testimonials-btn",
      },
      breakpoints: {
        991: {
          allowTouchMove: false,
        },
      },
    });
  },
};
</script>
<style lang="scss">
.section__testimonials {
  .swiper__container {
    position: relative;
    .swiper-nav {
      .swiper-button-prev,
      .swiper-button-next {
        position: static;
        display: inline-block;
        width: 45px;
        height: 45px;
        color: $main-dark;
        transition: all 0.3s;
        &::after {
          content: none;
        }
        &:hover {
          filter: brightness(50%);
        }
      }
      .swiper-button-prev {
        &:hover {
          transform: translateX(-6px);
        }
      }
      .swiper-button-next {
        &:hover {
          transform: translateX(6px);
        }
      }
    }
    .swiper-slide {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      &.swiper-slide-active {
        opacity: 1;
      }
      .testimonial__image {
        width: 75%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
        border: 12px solid $main-dark;
        img {
          width: 100%;
        }
      }
      .testimonial__info {
        font-family: "Cormorant Garamond", serif;
        .testimonial__name {
          font-size: 48px;
        }
        .testimonial__rating {
          font-size: 22px;
          color: #ffdf00;
        }
        .testimonial__message {
          font-size: 30px;
          font-weight: 500;
        }
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        transition: all 0.3s ease-in-out;
        &.swiper-pagination-bullet-active {
          background: $main-dark;
          scale: 1.4;
        }
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 125px;
      height: 125px;
      background-image: url("../../assets//images/quote.svg");
      background-size: contain;
      filter: invert(72%) sepia(46%) saturate(0%) hue-rotate(258deg) brightness(106%) contrast(92%);
      z-index: -1;
    }
    &::before {
      top: 0;
      left: 0;
      transform: rotate(180deg);
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
}
@media screen and (max-width: 1600px) {
  .section__testimonials {
    .swiper__container {
      .swiper-slide {
        .testimonial__info {
          .testimonial__name {
            font-size: 42px;
          }
          .testimonial__message {
            font-size: 26px;
          }
        }
      }
      &::before,
      &::after {
        width: 100px;
        height: 100px;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .section__testimonials {
    .swiper__container {
      .swiper-slide {
        .testimonial__info {
          .testimonial__name {
            font-size: 36px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .section__testimonials {
    .swiper__container {
      .swiper-nav {
        .swiper-button-prev,
        .swiper-button-next {
          &:hover {
            transform: none;
          }
        }
      }
      .swiper-slide {
        .testimonial__image {
          width: 55%;
        }
        .testimonial__info {
          .testimonial__name {
            font-size: 32px;
          }
          .testimonial__rating {
            font-size: 18px;
          }
          .testimonial__message {
            font-size: 24px;
          }
        }
      }
      &::before,
      &::after {
        width: 65px;
        height: 65px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .section__testimonials {
    .swiper__container {
      .swiper-nav {
        .swiper-button-prev,
        .swiper-button-next {
          width: 35px;
          height: 35px;
          &:hover {
            transform: none;
          }
        }
      }
      .swiper-slide {
        .testimonial__image {
          max-width: 250px;
          width: 75%;
        }
        .testimonial__info {
          .testimonial__name {
            font-size: 24px;
          }
          .testimonial__rating {
            font-size: 18px;
          }
          .testimonial__message {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
