<template>
  <section class="content__section section__contact py-5 hidden" id="Contact">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-12">
          <div class="contact__info text-lg-start text-center">
            <h2 class="section__title text-uppercase d-lg-inline-block mb-5">
              {{ $t("contacts.title") }}
            </h2>
            <p class="contact__description">
              {{ $t("contacts.description") }}
            </p>
            <a
              href="tel:+359892904599"
              class="contact__phone d-inline-block mb-4"
              aria-label="Give us a call"
              ><i class="fa-solid fa-phone me-3"></i>+359 892904599</a
            >
          </div>
        </div>
        <div class="col-lg-6 col-12 order-first">
          <div class="contact__image text-center m-auto mb-4 mb-lg-0">
            <img
              :src="loadSrc('contact_bg.svg', null, 'backgrounds')"
              alt="Contact us"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { loadSrc } from "@/utils/utils";

export default {
  name: "ContactSection",
  methods: {
    loadSrc,
  },
};
</script>
<style lang="scss">
.section__contact {
  .contact__info {
    .contact__description {
      font-family: "Cormorant Garamond", serif;
      font-size: 24px;
      font-weight: 500;
    }
    .contact__phone {
      text-decoration: none;
      font-size: 42px;
      font-weight: 500;
      color: $dark-grey;
      transition: all 0.25s ease-in-out;
      text-decoration: underline;
      color: $main-dark;
      &:hover {
        color: #897232;
      }
    }
  }
  .contact__image {
    width: 70%;
    aspect-ratio: 1 / 1;
    img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(0%)
        hue-rotate(39deg) brightness(101%) contrast(91%);
    }
  }
}
@media screen and (max-width: 1399px) {
  .section__contact {
    .contact__info {
      .contact__phone {
        font-size: 34px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .section__contact {
    .contact__info {
      .contact__description {
        font-size: 22px;
      }
      .contact__phone {
        font-size: 30px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .section__contact {
    .contact__info {
      .contact__description {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .section__contact {
    .contact__info {
      .contact__phone {
        font-size: 26px;
      }
    }
  }
}
</style>
