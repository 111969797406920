<template>
  <div id="app">
    <MainNav />
    <Home />
    <MainFooter />
    <ScrollToTopBtn />
  </div>
</template>

<script>
import MainNav from "./components/layout/MainNav.vue";
import MainFooter from "./components/layout/MainFooter.vue";
import Home from "./components/pages/Home.vue";
import ScrollToTopBtn from "./components/misc/ScrollToTopBtn.vue";

export default {
  name: "App",
  components: {
    MainNav,
    MainFooter,
    Home,
    ScrollToTopBtn
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  },
};
</script>

<style lang="scss"></style>
