<template>
  <section class="content__section section__gallery py-5" id="Gallery">
    <img src="../../assets/images/logo_icon_colored.svg" alt="logo" class="logo__icon" />
    <h2 class="section__title title__light text-uppercase text-center mb-5 hidden">
      {{ $t("gallery.title") }}
    </h2>
    <div class="container hidden">
      <GallerySwiper :images="images" />
    </div>
  </section>
</template>
<script>
import GallerySwiper from "../sliders/GallerySwiper.vue";
import images from "../../assets/data/gallery.json";

export default {
  name: "GallerySection",
  components: { GallerySwiper },
  data() {
    return {
      images,
    };
  },
};
</script>
<style lang="scss">
.section__gallery {
  background: linear-gradient(90deg, rgba(14, 14, 14, 1) 0%, rgb(50, 50, 50) 50%, rgba(14, 14, 14, 1) 100%);
  box-shadow: inset 0px 10px 12px #000, inset 0px -10px 12px #000;
  position: relative;
  overflow: hidden;
  .logo__icon {
    position: absolute;
    width: 150px;
    aspect-ratio: 1 / 1;
    right: 10px;
    top: 10px;
    opacity: 0.4;
    transition: opacity 0.25s;
  }
  &:hover {
    .logo__icon {
      opacity: 0.8;
    }
  }
}
@media screen and (max-width: 767px) {
  .section__gallery {
    .logo__icon {
      width: 120px;
    }
  }
}
@media screen and (max-width: 576px) {
  .section__gallery {
    .logo__icon {
      width: 90px;
    }
  }
}
</style>
