<template>
  <section class="content__section section__location py-5" id="Location">
    <h2 class="section__title title__light text-uppercase text-center mb-5 hidden">
      {{ $t("location.title") }}
    </h2>
    <div class="container card__container hidden">
      <div class="row align-items-center">
        <div class="col-md-4 col-12">
          <div class="location__info text-center py-4 py-md-0 px-3">
            <img src="@/assets/images/location_pin.svg" alt="Location Pin" width="100%" height="100%" />
            <p class="mt-3">
              {{ $t("location.address") }}
            </p>
          </div>
        </div>
        <div class="col-md-8 col-12">
          <div class="location__map p-3">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11832.929994916964!2d24.732157!3d42.1453113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14acd1abdac80095%3A0xb636ca5c3445b72e!2z0KHRgtGD0LTQuNC-INC30LAg0LrRgNCw0YHQvtGC0LAg0JTRitCz0LjRgtC1INC90LAg0J3QuNC60L7Quw!5e0!3m2!1sbg!2sbg!4v1693241759513!5m2!1sbg!2sbg" width="100%" height="350" title="locationMap" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "LocationSection",
};
</script>
<style lang="scss">
.section__location {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/images/backgrounds/location_bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .card__container {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
  }
  .location__info {
    img {
      max-width: 100%;
      height: 130px;
      filter: drop-shadow(2px 4px 6px #222);
    }
    p {
      font-family: "Poiret One", cursive;
      font-size: 34px;
      font-weight: 700;
      color: $white;
    }
  }
  .location__map {
    width: 100%;
  }
  .row {
    > div:last-child {
      border-left: 1px solid rgba(54, 57, 60, 0.1);
    }
  }
}
@media screen and (max-width: 1439px) {
  .section__location {
    .location__info {
      p {
        font-size: 30px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .section__location {
    .location__info {
      img {
        height: 110px;
      }
      p {
        font-size: 26px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .section__location {
    .row {
      > div:last-child {
        border-left: 0;
        border-top: 1px solid rgba(54, 57, 60, 0.1);
      }
    }
    .location__info {
      p {
        font-size: 28px;
      }
    }
  }
}
</style>
