<template>
  <div class="swiper__container">
    <div class="swiper-nav d-flex justify-content-center">
      <div class="swiper-button-prev gallery-btn me-2">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <div class="swiper-button-next gallery-btn ms-2">
        <i class="fa-solid fa-arrow-right"></i>
      </div>
    </div>
    <div class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide px-2" v-for="(image, index) in images" :key="index">
          <img :src="loadSrc(image.image_url, null, 'gallery')" width="100%" height="100%" alt="Photo of the saloon" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { loadSrc } from "../../utils/utils.js";

export default {
  name: "GallerySwiper",
  props: ["images"],
  methods: { loadSrc },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      loop: true,
      speed: 300,
      freeMode: true,
      freeModeMomentum: false,
      freeModeMomentumBounce: false,
      grabCursor: true,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        reverseDirection: true,
      },
      navigation: {
        nextEl: ".swiper-button-next.gallery-btn",
        prevEl: ".swiper-button-prev.gallery-btn",
      },
      breakpoints: {
        991: {
          slidesPerView: 3,
        },
        567: {
          slidesPerView: 2,
        },
      },
    });
  },
};
</script>
<style lang="scss">
.section__gallery {
  .swiper__container {
    .swiper-nav {
      .swiper-button-prev,
      .swiper-button-next {
        position: static;
        display: inline-block;
        width: 40px;
        height: 40px;
        color: $white;
        transition: all 0.3s;
        &::after {
          content: none;
        }
        &:hover {
          filter: brightness(50%);
        }
      }
    }
    .swiper .swiper-wrapper {
      transition-timing-function: linear;
      padding-top: 20px;
      .swiper-slide {
        img {
          border-radius: 4px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .section__gallery {
    .swiper__container {
      .swiper-nav {
        .swiper-button-prev,
        .swiper-button-next {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .section__gallery {
    .swiper__container {
      .swiper-slide {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
  }
}
</style>
