<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/logo_full_colored.svg" alt="Nicole's Rainbows Logo" />
      </a>
      <button class="btn d-lg-none d-block" type="button" data-bs-toggle="offcanvas" data-bs-target="#mainNavContent" aria-label="Open menu">
        <i class="fas fa-bars"></i>
      </button>
      <div class="offcanvas offcanvas-end" id="mainNavContent">
        <div class="offcanvas-body">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#" data-bs-dismiss="offcanvas" @click="scrollTo('About')">{{ $t("mainNav.about") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-bs-dismiss="offcanvas" @click="scrollTo('Services')">{{ $t("mainNav.services") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-bs-dismiss="offcanvas" @click="scrollTo('Gallery')">{{ $t("mainNav.gallery") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-bs-dismiss="offcanvas" @click="scrollTo('Testimonials')">{{ $t("mainNav.testimonials") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-bs-dismiss="offcanvas" @click="scrollTo('Location')">{{ $t("mainNav.location") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-bs-dismiss="offcanvas" @click="scrollTo('Contact')">{{ $t("mainNav.contact") }}</a>
            </li>
            <LanguageSelect />
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageSelect from "../misc/LanguageSelect.vue";

export default {
  name: "MainNav",
  components: { LanguageSelect },
  methods: {
    scrollTo: function (section) {
      const offsetPosition = document.getElementById(section).getBoundingClientRect().top - 75;

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.navbar {
  height: 75px;
  background-color: $darker-grey;
  font-family: "Cormorant Garamond", serif;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  .navbar-brand {
    height: 55px;
    transition: all 0.2s ease-in-out;
    img {
      height: 100%;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $main-light;
        font-weight: 700;
        font-size: 28px;
        transition: all 0.2s ease-in-out;
        padding-left: 4px;
        &:hover {
          text-shadow: 0px 0px 6px #fff;
        }
      }
    }
  }
  .btn {
    font-size: 25px;
    .fa-bars {
      color: $main-dark;
    }
  }
  .offcanvas {
    background-color: rgba(0, 0, 0, 1);
    flex-grow: 0 !important;
    &.offcanvas-end {
      border: none;
    }
    .offcanvas-header {
      .fa-x {
        color: $main-dark;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .navbar {
    .offcanvas {
      background: linear-gradient(90deg, rgba(14, 14, 14, 1) 0%, rgba(20, 20, 20, 1) 50%, rgba(14, 14, 14, 1) 100%);
      box-shadow: -2px 0px 10px $black;
      max-width: 80%;
      .offcanvas-body {
        .navbar-nav {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          text-align: left;
          .nav-item {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
