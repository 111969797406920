<template>
  <li class="nav-item dropdown lang-dropdown">
    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <img :src="loadSrc(this.$i18n.locale, 'svg', 'flags')" alt="Country Icon" />
    </a>
    <ul class="dropdown-menu">
      <li v-for="(lang, index) in langs" :key="index">
        <a v-if="lang != $i18n.locale" href="#" class="dropdown-item d-flex" @click.prevent="switchLangTo(lang)">
          <img :src="loadSrc(lang, 'svg', 'flags')" alt="Country Icon" />
          <span class="ms-2">{{ lang.toLocaleUpperCase() }}</span>
        </a>
      </li>
    </ul>
  </li>
</template>
<script>
import { loadSrc } from "../../utils/utils.js";

export default {
  name: "LanguageSelect",
  data() {
    return {
      langs: this.$i18n.availableLocales,
    };
  },
  methods: {
    switchLangTo(locale) {
      this.$i18n.locale = locale;
    },
    loadSrc,
  },
};
</script>
<style lang="scss">
.navbar {
  .lang-dropdown {
    .dropdown-toggle {
      transition: opacity 0.2s ease-in-out;
      img {
        width: 30px;
        aspect-ratio: 4 / 3;
      }
      &:hover {
        opacity: 0.7;
      }
      &::after {
        vertical-align: middle;
      }
      &.show {
        background-color: #2b2b2b;
        border-radius: 4px;
        box-shadow: none;
      }
    }
    .dropdown-menu {
      position: absolute;
      background-color: $darker-grey;
      min-width: auto;
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: #2b2b2b;
      }
      .dropdown-item {
        img {
          width: 30px;
          aspect-ratio: 4 / 3;
        }
        &:hover,
        &:focus {
          background-color: inherit;
        }
        span {
          color: $main-light;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
